import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Config from './modules/ckcore/core/Config';
import Dashboard from './modules/dashboard/Dashboard';
import * as serviceWorker from './serviceWorker';

// LIVE CONFIG
// set config URL to dashboard.chuyenkhoan.com
Config.setServerURL("https://dashboard.chuyenkhoan.com/dashboard?");
Config.setPaymentServerURL("https://thanhtoan.chuyenkhoan.com/pay/v1?");
Config.setCheckoutServerURL("https://thanhtoan.chuyenkhoan.com/checkout/v1/ckpay.html?");

ReactDOM.render(
    <Dashboard />,
    document.getElementById('ckdashboard')
);

serviceWorker.unregister();
