
import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import UserAccount from '../ckcore/account/UserAccount';
import { readCookie, eraseCookie } from '../ckcore/core/CkUtils';
import User from '../ckcore/core/User';
import Config from '../ckcore/core/Config';
import TxStatus from '../ckcore/core/TxStatuses';
import Colors from '../ckcore/core/Colors';

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';

import SignOut from '../ckcore/account/SignOut';
import ProfileDialog from './home/ProfileDialog';
import CkAppBar from './home/CkAppBar';

import '../../App.css';
import TxTypes from '../ckcore/core/TxTypes';
import CkApps from './home/CkApps';
import SettingDialog from './home/SettingDialog';
import PasswordDialog from './home/PasswordDialog';


// Set partner title
if(window.ckDashboardTitle) {
  document.title = window.ckDashboardTitle;
}

// Update dashboard title
const updateDashboardTitle = (partnerName) => {
  document.title = partnerName + " - Chuyển Khoản Dashboard";
}

export default function Dashboard(props) {

  const classes = useStyles();

  const [userObj, setUserObj] = useState({});
  const [partnerObj, setPartnerObj] = useState({});

  const [selectedObj, setSelectedObj] = useState({
    appId: '',
    action: '',
    refreshTime: 0
  });

  // read user session from cookies
  const sessionCookie = readCookie("user_session");
  // var hasSession = false;
  var hasSession = false;
  if (sessionCookie && selectedObj.refreshTime === 0) {

    try {

      const userSession = JSON.parse(sessionCookie);

      User.setUserId(userSession.userId);
      User.setPhone(userSession.phone);
      User.setStatus(userSession.status);
      User.setSessionId(userSession.sessionId);

      if ((userSession.email)) {
        User.setEmail(userSession.email);
      }

      if (User.getSessionId()) {
        hasSession = true;
      }
    } catch (error) {
      console.log("Error get cookie data: " + error);
    }
  }

  // login state
  const [loggedIn, setLoggedIn] = useState(hasSession);

  // Login callback
  const onLoginCallback = (status, message) => {

    // clear user session
    if (status === false) {
      eraseCookie("user_session");
    }

    setLoggedIn(status);
  };


  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  // Logout button
  const handleLogout = (fullLogout) => {
    setShowLogoutDialog(false);
    onLogoutCallback(fullLogout);
  };
  // request open logout dialog
  const openLogoutDialog = () => {
    setShowLogoutDialog(true);
  };
  // request close logout dialog
  const closeLogoutDialog = () => {
    setShowLogoutDialog(false);
  };

  //  Request logout button
  const onLogoutCallback = (fullLogout) => {

    try {

      // clear token
      if (fullLogout) {
        eraseCookie(User.getDisplayPhone() + '_token');
      }

      eraseCookie("user_session");

      // notify app about logged out
      setLoggedIn(false);
    } catch (error) {
      console.log("Error request logout: " + error);
    }
  };

  /**
   * Request show app
   */
  const showApp = (appId, action) => {


    if (appId === "PROFILE") {

      setShowProfileDialog(true);
    } else if (appId === "PASSWORD") {

      setShowPasswordDialog(true);
    } else if (appId === "SETTING") {

      setShowSettingDialog(true);
    } else {
      
      const selectedAction = (action) ? action : '';

      console.log("Show app: " + appId + " action: " + selectedAction);
      setSelectedObj({ ...selectedObj, appId: appId, action: selectedAction, refreshTime: new Date().getTime() });
    }

  }

  /**
   * Callback to refresh user and configuration information
   */
  const refreshCallback = (appId, action) => {

    const selectedAction = (action) ? action : '';
    if (!(appId)) {
      // always reload partner ?
      loadPartnerData();
      setSelectedObj({ ...selectedObj, action: selectedAction, refreshTime: new Date().getTime() });
    } else {
      showApp(appId, selectedAction);

      // reload in 1 second late
      setTimeout(() => {

        // loadPartnerData();
      }, 1101);
    }
  }


  /**
   * Profile dialog
   */
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const closeProfileDialog = () => {
    setShowProfileDialog(false);
    loadPartnerData();
  };

   /**
   * Change password dialog
   */
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const closePasswordDialog = () => {
    setShowPasswordDialog(false);
    loadPartnerData();
  };

  /**
   * Setting dialog
   */
  const [showSettingDialog, setShowSettingDialog] = useState(false);
  const closeSettingDialog = () => {
    setShowSettingDialog(false);
    loadPartnerData();
  };


  // Load partner data
  const loadPartnerData = async () => {

    // refresh message
    const ckmsg = {
      txTypeId: TxTypes.PARTNER_ADMIN_INFO_LOAD,
      partnerId: 0,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      // console.log("Loading partner data..");
      const rawResponse = await fetch(Config.getServerURL() + "home=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        // update user info
        // console.log("Refresh user: " + JSON.stringify(response.user));
        setUserObj(response.user);

        if (!(selectedObj.appId)) {
          if (User.hasActivePartner(response.user)) {
            setSelectedObj({ ...selectedObj, appId: "HOME", refreshTime: new Date().getTime() });
          } else {
            setSelectedObj({ ...selectedObj, appId: "MEMBERS", refreshTime: new Date().getTime() });
          }
        } else {
          // update for refresh
          setSelectedObj({ ...selectedObj, refreshTime: new Date().getTime() });
        }

        // update partner object
        setPartnerObj(response.partner);

        // update available banks
        Config.setAvailableBanks(response.banks);

        if (response.partner.name) {
          // update title
          updateDashboardTitle(response.partner.name);
        }

        // show update email
        if (User.getEmail() === "") {

          if (response.user.email) {
            User.setEmail(response.user.email);
          } else {
            setShowProfileDialog(true);
          }
        }

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        // Reset user session
        onLogoutCallback();
      } else {
        console.log("Request partner info error with statuses: " + response.statusId);
        onLogoutCallback();
      }
    } catch (error) {
      console.log("Error... " + error);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      loadPartnerData();
    }
  }, [loggedIn]);

  // If logged in, then goto home page, else admin need signin
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>

        {
          loggedIn && !(selectedObj.appId) &&
          <Box >Đang tải..</Box>
        }

        {
          loggedIn && (selectedObj.appId) &&
          <Box className={classes.root}>
            <CkAppBar
              userObj={userObj}
              partnerObj={partnerObj}
              showApp={showApp}
              openLogoutDialog={openLogoutDialog}
              refreshCallback={refreshCallback} />

            <CkApps
              userObj={userObj}
              partnerObj={partnerObj}
              selectedAppId={selectedObj.appId}
              action={selectedObj.action}
              refreshTime={selectedObj.refreshTime}
              showApp={showApp}
              logoutCallback={handleLogout}
              refreshCallback={refreshCallback} />

            {
              (showLogoutDialog) &&
              <SignOut
                open={showLogoutDialog}
                closeCallback={closeLogoutDialog}
                logoutCallback={handleLogout} />
            }

            {
              (showProfileDialog) &&
              <ProfileDialog
                open={showProfileDialog}
                closeCallback={closeProfileDialog}
                userObj={userObj}
                partnerObj={partnerObj}
                selectedAppId={selectedObj.appId}
                action={selectedObj.action}
                logoutCallback={handleLogout}
                refreshCallback={refreshCallback} />
            }

            {
              (showPasswordDialog) &&
              <PasswordDialog
                open={showPasswordDialog}
                closeCallback={closePasswordDialog}
                userObj={userObj}
                partnerObj={partnerObj}
                selectedAppId={selectedObj.appId}
                action={selectedObj.action}
                logoutCallback={handleLogout}
                refreshCallback={refreshCallback} />
            }

            {
              (showSettingDialog) &&
              <SettingDialog
                open={showSettingDialog}
                closeCallback={closeSettingDialog}
                userObj={userObj}
                partnerObj={partnerObj}
                selectedAppId={selectedObj.appId}
                action={selectedObj.action}
                logoutCallback={handleLogout}
                refreshCallback={refreshCallback} />
            }
          </Box>
        }

        {
          !loggedIn &&
          <GoogleReCaptchaProvider reCaptchaKey={Config.getCaptchaKey()}>
            <UserAccount loginCallback={onLoginCallback} />
          </GoogleReCaptchaProvider>
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const useStyles = makeStyles((homeTheme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.CK_VIOLET,
      contrastText: "#fff" //button text white instead of black
    },
   
    action: {
      main: "#FFFFFF",
      // disabledBackground: 'set color of background here',
    },

    gray: {
      main: Colors.GRAY
    },
    
    text: {
    }
  },
});
