import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Colors from '../../ckcore/core/Colors';
import { Grid, Paper } from '@mui/material';
import { Link } from '@mui/material';


export default function StepOne(props) {
  const classes = useStyles();

  // return grid layout with two columns
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>

      <div>
        <Typography variant="subtitle1">
          <strong>Bước 1: </strong>
          <span> Tạo tổ chức, công ty, cửa hàng, hoặc website của bạn</span>
        </Typography>
        <p>
          <span>Sau khi tạo tổ chức xong, bạn có thể cập nhật thông tin, và thêm thành viên tại mục <Link className={classes.link} onClick={() => props.refreshCallback("DEVELOPER", 0)}>Cài đặt</Link>. </span>
          <span>Các thành viên trong tổ chức được phân làm ba nhóm quyền: Quản trị, Quản lý, và Nhân viên.</span>
        </p>
        <p>
          <span><strong>Quản trị: </strong>có toàn quyền cập nhật, sửa đổi thông tin tổ chức, thông tin tài khoản ngân hàng, và kết nối hệ thống.</span>
        </p>
        <p>
          <span><strong>Quản lý: </strong>có quyền xem, thêm nhân viên, xem thông tin ngân hàng, đơn hàng, và trạng thái kết nối của tổ chức.</span>
        </p>
        <p>
          <span><strong>Nhân viên: </strong>có quyền xem thông tin ngân hàng, đơn hàng, và trạng thái kết nối của tổ chức.</span>
        </p>
       
      </div>
    </Grid>
    <Grid item xs={12} md={6}>
      <p>
        <iframe
          width="480"
          height="270"
          src="https://www.youtube.com/@chuyenkhoan"
          title="Chuyenkhoan.com com - Tạo tổ chức"
          allowfullscreen
        ></iframe>
        </p>
    </Grid>
  </Grid>
  );

}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FFFBE6',
    marginBottom: 20
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  guide: {
    paddingLeft: 40,
    paddingBottom: 20,
  },
  link: {
    cursor: 'pointer',
    color: Colors.DARK_BLUE,
    fontWeight: 'bold'
  }
}));
