import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Colors from '../../ckcore/core/Colors';
import { Grid, Paper } from '@mui/material';
import { Link } from '@mui/material';


export default function StepFour(props) {
  const classes = useStyles();

  return (


    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>

        <div>
          <Typography variant="subtitle1">
            <strong>Bước 4: </strong>
            <span> Kết nối tạo mã </span>
            <Link className={classes.link} onClick={() => props.refreshCallback("DEVELOPER", 1)}>QR chuyển khoản 24/7</Link>
          </Typography>
          <p>
            <span>Việc tích hợp hiện mã QR chuyển khoản VietQR (CKQR) lên website của bạn dễ dàng như thêm Google Analystics.</span>
          </p>
          <p>
            <strong>CKQR tĩnh: </strong>
            <span>Bạn chỉ cần nhúng thư viện ckqr của Chuyenkhoan.com lên website và cấu hình tài khoản nhận tiền. </span>
          </p>
          <p>
            <strong>CKQR động: </strong>
            <span>Sau khi cấu hình tài khoản nhận tiền qua thư viện ckqr của Chuyenkhoan.com, bạn cần thêm thông tin đơn hàng cần thanh toán (số tiền, nội dung..)</span>
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <p>
          <iframe
            width="480"
            height="270"
            src="https://www.youtube.com/@chuyenkhoan"
            title="Chuyenkhoan.com - Tạo mã QR chuyển khoản"
            allowfullscreen
          ></iframe>
        </p>
      </Grid>
    </Grid>
  );

}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FFFBE6',
    marginBottom: 20
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  guide: {
    paddingLeft: 40,
    paddingBottom: 20,
  },
  link: {
    cursor: 'pointer',
    color: Colors.DARK_BLUE,
    fontWeight: 'bold'
  }
}));
